<template>
  <div>
    <div class="row mt-5">
      <div class="col-md-10 offset-md-1">
        <div class="row">
          <div class="col-md-6">
            <h4>Shipment Booking Form</h4>
          </div>
          <div class="col-md-6">
            <p class="text-right">
              Fields marked with(*) are mandatory details.
            </p>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">1. Sender Information</h3>
            </div>
            <div class="card-toolbar">
              <b-button variant="light" @click="showAddressbook(1)" class="mr-2" size="sm" v-b-modal.address-book-modal><i
                  class="flaticon2-location"></i> Address book</b-button>
              <b-button variant="light" size="sm" @click="saveAddress(1)"><i class="flaticon2-add-1"></i> Save Address
              </b-button>
            </div>
          </div>
          <div class="card-body body-fluid">
            <div class="row">
              <div class="col-md-4">
                <label class="font-weight-bold">Name <span class="text-danger">*</span></label>
                <b-form-input placeholder="Sender name" v-model="form.sender_name"></b-form-input>
              </div>
              <div class="col-md-4">
                <label class="font-weight-bold">Phone <span class="text-danger">*</span></label>
                <b-form-input placeholder="Phone number" v-model="form.sender_phone"></b-form-input>
              </div>
              <div class="col-md-4">
                <label class="font-weight-bold">Email</label>
                <b-form-input placeholder="Email Address" v-model="form.sender_email"></b-form-input>
              </div>
              <div class="col-md-4 mt-2">
                <label class="font-weight-bold">Country <span class="text-danger">*</span></label>
                <b-form-select :options="countries" value-field="name" text-field="name" v-model="form.sender_country"
                  @change="changeCountrySender" size="md" class=""></b-form-select>
              </div>
              <div class="col-md-4 mt-2">
                <label class="font-weight-bold">State <span class="text-danger">*</span></label>
                <b-form-select :options="statessender" value-field="name" text-field="name" v-model="form.sender_state"
                  size="md" class="">
                  <template #first>
                    <b-form-select-option :value="null" disabled>Select State</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-md-4 mt-2">
                <label class="font-weight-bold">Zip code <span class="text-danger">*</span></label>
                <b-form-input id="postal" type="text" v-model="form.sender_zipcode" placeholder="Postal Code"
                  @change="changePostalCodeSender" @keyup="changePostalCodeSender"></b-form-input>
              </div>
              <div class="col-md-8 mt-2">
                <label class="font-weight-bold">Street Address <span class="text-danger">*</span></label>
                <b-form-input placeholder="Street address" v-model="form.sender_street_address"></b-form-input>
              </div>
              <div class="col-md-4 mt-2">
                <label class="font-weight-bold">City <span class="text-danger">*</span></label>
                <b-form-input placeholder="City name" v-model="form.sender_city"></b-form-input>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">2. Receiver Information</h3>
            </div>
            <div class="card-toolbar">
              <b-button variant="light" @click="showAddressbook(2)" class="mr-2" size="sm" v-b-modal.address-book-modal><i
                  class="flaticon2-location"></i> Address book</b-button>
              <b-button variant="light" size="sm" @click="saveAddress(2)"><i class="flaticon2-add-1"></i> Save Address
              </b-button>
            </div>
          </div>
          <div class="card-body body-fluid">
            <div class="row">
              <div class="col-md-4">
                <label class="font-weight-bold">Name <span class="text-danger">*</span></label>
                <b-form-input placeholder="Receiver name" v-model="form.receiver_name"></b-form-input>
              </div>
              <div class="col-md-4">
                <label class="font-weight-bold">Phone <span class="text-danger">*</span></label>
                <b-form-input placeholder="Phone number" v-model="form.receiver_phone"></b-form-input>
              </div>
              <div class="col-md-4">
                <label class="font-weight-bold">Email </label>
                <b-form-input placeholder="Email Address" v-model="form.receiver_email"></b-form-input>
              </div>
              <div class="col-md-4 mt-2">
                <label class="font-weight-bold">Country <span class="text-danger">*</span></label>
                <b-form-select :options="countries" value-field="name" text-field="name" v-model="form.receiver_country"
                  @change="changeCountryReceiver" size="md" class=""></b-form-select>
              </div>
              <div class="col-md-4 mt-2">
                <label class="font-weight-bold">State <span class="text-danger">*</span></label>
                <b-form-select :options="statesreceiver" value-field="name" text-field="name"
                  v-model="form.receiver_state" size="md" class="">
                  <template #first>
                    <b-form-select-option :value="null" disabled>Select State</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-md-4 mt-2">
                <label class="font-weight-bold">Zip code <span class="text-danger">*</span></label>
                <b-form-input id="postal" type="text" v-model="form.receiver_zipcode" placeholder="Postal Code"
                  @change="changePostalCodeReceiver" @keyup="changePostalCodeReceiver"></b-form-input>
              </div>
              <div class="col-md-8 mt-2">
                <label class="font-weight-bold">Street Address <span class="text-danger">*</span></label>
                <b-form-input placeholder="Street address" v-model="form.receiver_street_address"></b-form-input>
              </div>
              <div class="col-md-4 mt-2">
                <label class="font-weight-bold">City <span class="text-danger">*</span></label>
                <b-form-input placeholder="City name" v-model="form.receiver_city"></b-form-input>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">3. Product Information</h3>
            </div>
            <div class="card-toolbar">
              <b-button variant="secondary" @click="addProduct"><i class="flaticon2-plus"></i> Add item</b-button>
            </div>
          </div>
          <div class="card-body body-fluid">
            <div class="row">
              <div class="col-2">
                <div class="form-group">
                  <label class="mr-3"> Used Personal Effects </label>
                  <input type="checkbox" v-model="form.personal_effects" class="mt-1" />
                </div>
              </div>
              <div class="col-10">
                <p class="ml-0 float-left text-left">
                  If you select <i>Used Personal Effects</i> then you can only
                  select the 4 package size of all of your items
                </p>
              </div>

              <div class="col-12" v-if="form.personal_effects">
                <div class="row">
                  <div class="col-4"></div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>Select Box Size</label>
                      <b-form-select :options="personalEffectList" value-field="id" text-field="name"
                        v-model="selectedPersonalEffect" @change="selectPersonalEffect" size="md" class="">Select Box
                        Size</b-form-select>
                    </div>
                  </div>
                  <div class="col-4"></div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table text-nowrap" id="productTable">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Weight Scale</th>
                    <th>Size Scale</th>
                    <th>Length</th>
                    <th>Width</th>
                    <th>Height</th>
                    <th>Dimensional weight</th>
                    <th>Actual weight</th>
                    <th>Final weight</th>
                    <th>Qty</th>
                    <th>Currency</th>
                    <th>Value</th>
                    <th>Total value</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody id="p_scents">
                  <tr class="item" v-for="(itemproduct, index) in form.products" :key="index">
                    <td>
                      <input style="min-width: 200px" type="text" class="form-control productName" list="producttypes"
                        placeholder="Product description" id="pname" v-model="itemproduct.description" />
                    </td>
                    <td>
                      <b-form-select :options="weightscales" value-field="name" v-model="itemproduct.weight_scale"
                        text-field="name" class="form-control" @change="CalculateDimensionalWeight(index)">
                      </b-form-select>
                    </td>
                    <td>
                      <b-form-select :options="sizescales" value-field="name" v-model="itemproduct.size_scale"
                        text-field="name" class="form-control">
                      </b-form-select>
                    </td>
                    <td>
                      <input type="number" step=".01" placeholder="0.00" class="form-control length" name="plength[]"
                        v-model="itemproduct.length" id="plength" @keyup="CalculateDimensionalWeight(index)"
                        @change="CalculateDimensionalWeight(index)" />
                    </td>
                    <td>
                      <input type="number" step=".01" placeholder="0.00" class="form-control width" name="pwidth[]"
                        v-model="itemproduct.width" id="pwidth" @keyup="CalculateDimensionalWeight(index)"
                        @change="CalculateDimensionalWeight(index)" />
                    </td>
                    <td>
                      <input type="number" step=".01" placeholder="0.00" class="form-control height"
                        v-model="itemproduct.height" @keyup="CalculateDimensionalWeight(index)"
                        @change="CalculateDimensionalWeight(index)" />
                    </td>
                    <td>
                      <input type="text" class="form-control vweight" name="pvweight[]" readonly=""
                        v-model="itemproduct.dimensional_weight" placeholder="0.00" />
                    </td>
                    <td>
                      <input type="text" class="form-control aweight" name="paweight[]" placeholder="0.00"
                        v-model="itemproduct.actual_weight" @change="CalculateFinalWeight(index)"
                        @keyup="CalculateFinalWeight(index)" />
                    </td>
                    <td>
                      <input type="number" step=".01" class="form-control weight" readonly="" placeholder="0.00"
                        name="pweight[]" v-model="itemproduct.final_weight" />
                    </td>
                    <td>
                      <input type="number" placeholder="0" id="pquantity" name="pquantity[]" class="form-control quantity"
                        v-model="itemproduct.qty" @change="CalculateTotalValue(index)"
                        @keyup="CalculateTotalValue(index)" />
                    </td>
                    <td>
                      <b-form-select :options="currencies" value-field="code" v-model="itemproduct.currency"
                        text-field="code" class="form-control">
                      </b-form-select>
                    </td>
                    <td>
                      <input type="number" step=".01" placeholder="0.00" class="form-control price" value="0.00"
                        name="pprice[]" v-model="itemproduct.value" id="pprice" @change="CalculateTotalValue(index)"
                        @keyup="CalculateTotalValue(index)" />
                    </td>
                    <td>
                      <input type="number" step=".01" placeholder="0.00" class="form-control total" value="0.00"
                        readonly="" v-model="itemproduct.total" name="ptotal[]" id="ptotal" />
                    </td>
                    <td>
                      <b-button @click="removeProduct(index)" class="ml-2" size="sm" variant="danger">
                        <i class="flaticon2-trash icon-sm"></i></b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">4. Shipment Information</h3>
            </div>
          </div>
          <div class="card-body body-fluid">
            <div class="row">
              <div class="col-md-3">
                <label class="font-weight-bold">Total Weight(Kgs) <span class="text-danger">*</span></label>
                <b-form-input type="number" placeholder="e.g. 1.5" v-model="form.total_weight"></b-form-input>
              </div>
              <div class="col-md-3">
                <label class="font-weight-bold">Shipment Payer <span class="text-danger">*</span></label>
                <b-form-select :options="shipmentpayerlist" value-field="name" v-model="form.shipment_payer"
                  text-field="name">
                </b-form-select>
              </div>
              <div class="col-md-3">
                <label class="font-weight-bold">Shipping Mode <span class="text-danger">*</span></label>
                <b-form-select :options="shippingmodelist" value-field="name" v-model="form.shipping_mode"
                  text-field="name">
                </b-form-select>
              </div>
              <div class="col-md-3">
                <label class="font-weight-bold">Shipment Type <span class="text-danger">*</span></label>
                <b-form-select :options="shipmenttypelist" value-field="name" v-model="form.shipment_type"
                  text-field="name">
                </b-form-select>
              </div>
              <div class="col-md-12 mt-2">
                <label class="font-weight-bold">Instructions
                  <span class="text-danger">( optional )</span></label>
                <b-form-textarea placeholder="Write your note here" v-model="form.instructions"></b-form-textarea>
              </div>
              <div class="col-md-3 mt-2">
                <label class="font-weight-bold">Consolidation Required?</label>
                <b-form-select :options="YesNolist" value-field="name" v-model="form.consolidation_required"
                  text-field="name">
                  <template #first>
                    <b-form-select-option :value="null">Choose</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-md-3 mt-2">
                <label class="font-weight-bold">Total Orders</label>
                <b-form-input type="number" v-model="form.total_orders"></b-form-input>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <b-button variant="primary" @click="bookshipment">Book Shipment</b-button>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="address-book-modal" ref="modal" ok-title="Close" title="Address Book" size="lg" ok-only>
      <div class="row" v-for="(item, index) in addressbook" :key="index">
        <div class="col-md-9">
          <span class="float-left">
            <h5 class="text-purple">{{ item.name }}</h5>

            {{ item.phone }}
            <br />
            {{
              item.street_address +
              ", " +
              item.city +
              "/" +
              item.country +
              ", " +
              item.state +
              " / " +
              item.zip
            }}
          </span>
        </div>
        <div class="col-md-3">
          <b-button variant="primary" @click="chooseaddress(item.id)" class="float-right">Choose address</b-button>
        </div>
      </div>
    </b-modal>
    <datalist id="producttypes">
      <option v-for="(item, index) in proddesclist" :key="index">
        {{ item.name }}
      </option>
    </datalist>
    <loading :active.sync="isLoading"></loading>
  </div>
</template>

<style>
.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>th {
  padding: 10px;
}

#productTable .form-control {
  min-width: 100px;
}

.vweight {
  width: 141px;
}
</style>

<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import singaporePostalCode from "@/assets/data/singaporePostalCode.json";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  data() {
    return {
      file: null,
      addressbooktype: null,
      isLoading: false,
      errors: [],
      adressObj: {},
      firstweightscale: null,
      firstsizescale: null,
      firstcurrency: null,
      selectedPersonalEffect: 0,
      activeProductIndex: 0,
      product: {
        description: null,
        weight_scale: "Kgs",
        size_scale: "CM",
        length: null,
        width: null,
        height: null,
        dimensional_weight: null,
        actual_weight: null,
        final_weight: null,
        qty: null,
        currency: "USD",
        value: null,
        total: null,
      },
      form: {
        receiver_id: null,
        receiver_name: null,
        receiver_phone: null,
        receiver_email: null,
        receiver_street_address: null,
        receiver_city: null,
        receiver_country: null,
        receiver_country_id: null,
        receiver_state: null,
        receiver_zipcode: null,
        sender_id: null,
        sender_name: null,
        sender_phone: null,
        sender_email: null,
        sender_street_address: null,
        sender_city: null,
        sender_country: null,
        sender_country_id: null,
        sender_state: null,
        sender_zipcode: null,
        instructions: null,
        total_weight: 0,
        shipping_mode: null,
        shipment_payer: null,
        shipment_type: null,
        consolidation_required: null,
        total_orders: null,
        products: [],
        personal_effects: false,
      },
      virtualaddresses: [],
      addressbook: [],
      allstates: [],
      statesreceiver: [],
      statessender: [],
      countries: [],
      personalEffectList: [],
      shipmentpayerlist: [],
      proddesclist: [],
      weightscales: [],
      sizescales: [],
      currencies: [],
      shippingmodelist: [],
      shipmenttypelist: [],
      YesNolist: [
        {
          name: "Yes",
        },
        {
          name: "No",
        },
      ],

      singaportpostalcodes: singaporePostalCode,
    };
  },
  components: {
    Loading,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Shipment",
      },
      {
        title: "Add",
      },
    ]);
    this.isLoading = true;
    this.loadData();
    this.getAllStates();
    this.form.products.push({
      description: null,
      weight_scale: "Kgs",
      size_scale: "CM",
      length: null,
      width: null,
      height: null,
      dimensional_weight: null,
      actual_weight: null,
      final_weight: null,
      qty: null,
      currency: "USD",
      value: null,
      total: null,
    });
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    showAddressbook(type) {
      this.addressbooktype = type;
    },
    chooseaddress(id) {
      if (this.addressbook.filter((x) => x.id == id).length > 0) {
        var address = this.addressbook.filter((x) => x.id == id)[0];
        if (this.addressbooktype == 1) {
          this.form.sender_id = id;
          this.form.sender_name = address.name;
          this.form.sender_phone = address.phone;
          this.form.sender_street_address = address.street_address;
          this.form.sender_city = address.city;
          this.form.sender_country = address.country;
          this.form.sender_country_id = address.country_id;
          this.form.sender_state = address.state;
          this.form.sender_zipcode = address.zip;
          this.form.sender_email = address.email;
          this.statessender = [{ id: 1, name: address.state }];
          this.$bvModal.hide("address-book-modal");
        }
        if (this.addressbooktype == 2) {
          this.form.receiver_id = id;
          this.form.receiver_name = address.name;
          this.form.receiver_phone = address.phone;
          this.form.receiver_street_address = address.street_address;
          this.form.receiver_city = address.city;
          this.form.receiver_country = address.country;
          this.form.receiver_country_id = address.country_id;
          this.form.receiver_state = address.state;
          this.form.receiver_zipcode = address.zip;
          this.form.receiver_email = address.email;

          this.statesreceiver = [{ id: 1, name: address.state }];
          this.$bvModal.hide("address-book-modal");
        }
      }
    },
    saveAddress(type) {
      if (type == 1) {
        this.adressObj.name = this.form.sender_name;
        this.adressObj.phone = this.form.sender_phone;
        this.adressObj.street_address = this.form.sender_street_address;
        this.adressObj.city = this.form.sender_city;
        this.adressObj.country = this.form.sender_country;
        this.adressObj.state = this.form.sender_state;
        this.adressObj.zip = this.form.sender_zipcode;
        this.adressObj.email = this.form.sender_email;
        this.adressObj.country_id = this.form.sender_country_id;
      }
      if (type == 2) {
        this.adressObj.name = this.form.receiver_name;
        this.adressObj.phone = this.form.receiver_phone;
        this.adressObj.street_address = this.form.receiver_street_address;
        this.adressObj.city = this.form.receiver_city;
        this.adressObj.country = this.form.receiver_country;
        this.adressObj.state = this.form.receiver_state;
        this.adressObj.zip = this.form.receiver_zipcode;
        this.adressObj.email = this.form.receiver_email;
        this.adressObj.country_id = this.form.receiver_country_id;
      }
      this.adressObj.created_by = this.currentUser.id;
      this.isLoading = true;
      ApiService.post("addressbook", this.adressObj)
        .then((response) => {
          this.$nextTick(() => {
            this.makeToastVariant(
              "success",
              "Address saved successfully",
              "Success"
            );
            this.isLoading = false;
            this.getAddressBook();

          });
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
            this.makeToastVariant(
              "danger",
              response.data.error[field][0],
              "Error"
            );
          }
        });
    },
    CalculateTotalWeight() {
      this.form.total_weight = 0;
      this.form.products.forEach((value) => {
        if (value.final_weight > 0) {
          this.form.total_weight =
            parseFloat(this.form.total_weight) + parseFloat(value.final_weight);
        }
      });
      this.form.total_weight = Math.round(this.form.total_weight);
    },
    CalculateDimensionalWeight(index) {
      if (
        this.form.products[index].width > 0 &&
        this.form.products[index].height > 0 &&
        this.form.products[index].length > 0
      ) {
        // Calculate Dimensional Weight when weight scale is KG

        if (
          this.form.products[index].weight_scale.toLowerCase() ==
          "Kgs".toLowerCase()
        ) {
          this.form.products[index].dimensional_weight =
            (this.form.products[index].width *
              this.form.products[index].height *
              this.form.products[index].length) /
            5000;
        }
        // Calculate Dimensional Weight when weight scale is M3
        if (
          this.form.products[index].weight_scale.toLowerCase() ==
          "M3".toLowerCase()
        ) {
          this.form.products[index].dimensional_weight =
            (this.form.products[index].width / 100) *
            (this.form.products[index].height / 100) *
            (this.form.products[index].length / 100);
        }
        this.CalculateFinalWeight(index);
      } else {
        this.form.products[index].dimensional_weight = 0;
      }
      this.CalculateTotalWeight();
    },
    CalculateFinalWeight(index) {
      if (
        this.form.products[index].dimensional_weight >=
        this.form.products[index].actual_weight
      ) {
        this.form.products[index].final_weight =
          this.form.products[index].dimensional_weight;
      } else {
        this.form.products[index].final_weight =
          this.form.products[index].actual_weight;
      }
      this.CalculateTotalWeight();
    },
    CalculateTotalValue(index) {
      if (
        this.form.products[index].qty > 0 &&
        this.form.products[index].value > 0
      ) {
        this.form.products[index].total =
          this.form.products[index].qty * this.form.products[index].value;
      } else {
        this.form.products[index].total = 0;
      }
    },

    addProduct() {
      this.form.products.push({
        description: null,
        weight_scale: this.firstweightscale,
        size_scale: this.firstsizescale,
        length: null,
        width: null,
        height: null,
        dimensional_weight: null,
        actual_weight: null,
        final_weight: null,
        qty: null,
        currency: this.firstcurrency,
        value: null,
        total: null,
      });
      this.activeProductIndex = this.form.products.length - 1;
      this.selectedPersonalEffect = 0;
    },
    removeProduct(index) {
      this.form.products.splice(index, 1);
    },
    onFileChange(e) {
      this.file = e.target.files || e.dataTransfer.files;
      this.file = this.file[0];
    },
    checkErrors() {
      if (
        // this.form.total_weight &&
        this.form.shipment_payer &&
        this.form.shipping_mode &&
        this.form.shipment_type &&
        this.form.consolidation_required &&
        this.form.receiver_name &&
        this.form.receiver_phone &&
        this.form.receiver_street_address &&
        this.form.receiver_city &&
        this.form.receiver_country &&
        this.form.receiver_state &&
        this.form.receiver_zipcode &&
        this.form.sender_name &&
        this.form.sender_phone &&
        this.form.sender_street_address &&
        this.form.sender_city &&
        this.form.sender_country &&
        this.form.sender_state &&
        this.form.sender_zipcode
      ) {
        return true;
      }
      this.errors = [];

      // if (!this.form.total_weight) {
      //   this.errors.push("Total Weight is required.");
      // }
      if (!this.form.shipment_payer) {
        this.errors.push("Shipment Payer is required.");
      }
      if (!this.form.shipping_mode) {
        this.errors.push("Shipping Mode is required.");
      }
      if (!this.form.shipment_type) {
        this.errors.push("Shipment Type is required.");
      }
      if (!this.form.consolidation_required) {
        this.errors.push("Consolidation Required value is required.");
      }
      if (!this.form.receiver_name) {
        this.errors.push("Receiver's Name is required.");
      }
      if (!this.form.receiver_phone) {
        this.errors.push("Receiver's Phone is required.");
      }
      if (!this.form.receiver_street_address) {
        this.errors.push("Receiver's Street Address is required.");
      }
      if (!this.form.receiver_city) {
        this.errors.push("Receiver's City is required.");
      }
      if (!this.form.receiver_country) {
        this.errors.push("Receiver's Country is required.");
      }
      if (!this.form.receiver_state) {
        this.errors.push("Receiver's State is required.");
      }
      if (!this.form.receiver_zipcode) {
        this.errors.push("Receiver's Zip code is required.");
      }
      if (!this.form.sender_name) {
        this.errors.push("Sender's Name is required.");
      }
      if (!this.form.sender_phone) {
        this.errors.push("Sender's Phone is required.");
      }
      if (!this.form.sender_street_address) {
        this.errors.push("Sender's Street Address is required.");
      }
      if (!this.form.sender_city) {
        this.errors.push("Sender's City is required.");
      }
      if (!this.form.sender_country) {
        this.errors.push("Sender's Country is required.");
      }
      if (!this.form.sender_state) {
        this.errors.push("Sender's State is required.");
      }
      if (!this.form.sender_zipcode) {
        this.errors.push("Sender's Zip code is required.");
      }
      for (let field of this.errors) {
        this.makeToastVariant("danger", field, "Error");
      }
    },
    bookshipment() {
      if (this.checkErrors() == true) {
        if (this.file) {
          this.isLoading = true;
          let formData = new FormData();
          formData.append("file", this.file);
          ApiService.upload("adddocument", formData)
            .then((response) => {
              if (response.data.success) {
                this.isLoading = false;
                this.saveshipment(response.data.file);
              }
            })
            .catch(({ response }) => {
              this.isLoading = false;
              for (let field of Object.keys(response.data.error)) {
                this.makeToastVariant(
                  "danger",
                  response.data.error[field][0],
                  "Error"
                );
              }
            });
        } else {
          this.saveshipment();
        }
      }
    },
    saveshipment(filepath = "") {
      this.form.purchase_invoice_url = filepath;
      if (this.checkErrors() == true) {
        this.isLoading = true;
        ApiService.post("bookshipment", this.form)
          .then(() => {
            this.$nextTick(() => {
              this.makeToastVariant(
                "success",
                "Shipment added successfully",
                "Success"
              );
              this.isLoading = false;
              this.$router.push({
                name: "shipments",
              });
            });
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    makeToastVariant(variant = null, text, title) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    changePostalCodeReceiver() {
      if (
        this.form.receiver_country.toLowerCase() == "Singapore".toLowerCase() &&
        this.singaportpostalcodes.filter(
          (f) => f.POSTAL == this.form.receiver_zipcode
        ).length > 0
      ) {
        this.form.receiver_street_address = this.singaportpostalcodes.filter(
          (f) => f.POSTAL == this.form.receiver_zipcode
        )[0].ADDRESS;
        this.form.receiver_city = "Singapore";
      }
    },
    changePostalCodeSender() {
      if (
        this.form.sender_country.toLowerCase() == "Singapore".toLowerCase() &&
        this.singaportpostalcodes.filter(
          (f) => f.POSTAL == this.form.sender_zipcode
        ).length > 0
      ) {
        this.form.sender_street_address = this.singaportpostalcodes.filter(
          (f) => f.POSTAL == this.form.sender_zipcode
        )[0].ADDRESS;
        this.form.sender_city = "Singapore";
      }
    },

    getAllStates() {
      ApiService.get("getstates")
        .then(({ data }) => {
          this.allstates = data;
          this.statessender = data;
          this.statesreceiver = data;
          if (
            this.countries.filter((x) => x.name == this.form.receiver_country)
              .length > 0
          ) {
            if (
              this.allstates.filter(
                (x) =>
                  x.country_id ==
                  this.countries.filter(
                    (x) => x.name == this.form.receiver_country
                  )[0].id
              ).length > 0
            ) {
              this.statesreceiver = this.statessender = this.allstates.filter(
                (x) =>
                  x.country_id ==
                  this.countries.filter(
                    (x) => x.name == this.form.receiver_country
                  )[0].id
              );
            }
          }
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    changeCountryReceiver() {
      var countryId = this.countries.filter((x) => x.name == this.form.receiver_country)[0].id;
      this.form.receiver_country_id = countryId;
      ApiService.get("getStatesByCountryId", countryId)
        .then(({ data }) => {

          // this.allstates = data;
          this.statesreceiver = data;
          this.isLoading = false;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
     
    },
    changeCountrySender() {
      var countryId = this.countries.filter((x) => x.name == this.form.sender_country)[0].id;
      this.form.sender_country_id = countryId;
      ApiService.get("getStatesByCountryId", countryId)
        .then(({ data }) => {

          // this.allstates = data;
          this.statessender = data;
          this.isLoading = false;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
      // if (
      //   this.countries.filter((x) => x.name == this.form.sender_country)
      //     .length > 0
      // ) {
      //   if (
      //     this.allstates.filter(
      //       (x) =>
      //         x.country_id ==
      //         this.countries.filter(
      //           (x) => x.name == this.form.sender_country
      //         )[0].id
      //     ).length > 0
      //   ) {
      //     this.form.sender_country_id = this.countries.filter(
      //           (x) => x.name == this.form.receiver_country
      //         )[0].id;
      //     this.statessender = this.allstates.filter(
      //       (x) =>
      //         x.country_id ==
      //         this.countries.filter(
      //           (x) => x.name == this.form.sender_country
      //         )[0].id
      //     );
      //     this.form.sender_state = null;
      //   }
      // }
      // //If Country has only one State auto select that state PW-68
      // if (this.statessender.length == 1) {
      //   this.form.sender_state = this.statessender[0].name;
      // }
    },
    getAddressBook() {
      ApiService.get("addressbook")
        .then(({
          data
        }) => {
          this.addressbook = data;
        })
        .catch(() => {
        });
    },
    loadData() {
      ApiService.get("getpageload")
        .then(({ data }) => {
          this.addressbook = data.addressbook;
          this.countries = data.countries;
          this.form.receiver_country = this.countries.length
            ? this.countries[0].name
            : null;

          this.virtualaddresses = data.va;
          this.form.shipping_address = this.virtualaddresses.length
            ? this.virtualaddresses[0].id
            : null;

          this.proddesclist = data.productDescriptions;
          this.couriers = data.courier;
          this.shippingmodelist = data.shippingModes;
          this.form.shipping_mode = this.shippingmodelist.length
            ? this.shippingmodelist[0].name
            : null;
          this.shipmenttypelist = data.shipmentTypes;
          this.form.shipment_type = this.shipmenttypelist.length
            ? this.shipmenttypelist[0].name
            : null;
          this.currencies = data.currencies;
          this.firstcurrency = this.currencies.length
            ? this.currencies[0].code
            : null;
          this.sizescales = data.sizescales;
          this.firstsizescale = this.sizescales.length
            ? this.sizescales[0].name
            : null;
          this.weightscales = data.weightScales;
          this.firstweightscale = this.weightscales.length
            ? this.weightscales[0].name
            : null;
          this.shipmentpayerlist = data.shipmentPayer;
          this.form.shipment_payer = this.shipmentpayerlist.length
            ? this.shipmentpayerlist[0].name
            : null;
          this.personalEffectList = data.pEffects;

          this.isLoading = false;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },

    selectPersonalEffect() {
      if (this.form.products.length < 1) {
        this.makeToastVariant(
          "warning",
          "Add a product to select the box size",
          "Warning"
        );
        this.selectedPersonalEffect = 0;
        return;
      }

      var boxSize = this.personalEffectList.filter(
        (x) => x.id == this.selectedPersonalEffect
      )[0];
      this.form.products[this.activeProductIndex].length = boxSize.length;
      this.form.products[this.activeProductIndex].width = boxSize.width;
      this.form.products[this.activeProductIndex].height = boxSize.height;
      this.CalculateDimensionalWeight(this.activeProductIndex);
    },
    changeState() {
      this.form.receiver_country_id = this.form.receiver_country;
      ApiService.get("getStatesByCountryId", this.form.receiver_country)
        .then(({ data }) => {

          // this.allstates = data;
          this.states = data;
          this.isLoading = false;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
  },
};
</script>
